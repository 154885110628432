import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css';

// import reportWebVitals from './reportWebVitals';
import LandingPage from './LandingPage';

class LandingRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
       <BrowserRouter>
          <Routes>
            <Route path={process.env.PUBLIC_URL + "/"} element={<LandingPage />} exact/>
            {/*<Route path={process.env.PUBLIC_URL + "/v2v/info/"} element={<V2vLandingPage />} exact/>*/}
          </Routes>
        </BrowserRouter>
     );
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LandingRouter />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
