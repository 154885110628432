import axios from "axios";

export function get_endpoint_root() {
  var hostname = window.location.hostname;
  var port = window.location.port;
  if(window.location.port && (port != 3000))
    hostname += ':' + window.location.port;
  return window.location.protocol + '//' + hostname + '/';
}

export function get_api_endpoint(mode, q_id = null) {
  if(mode == 'user')
    return get_endpoint_root() + 'accounts/current_user/';
  if(mode == 'project')
    return get_endpoint_root() + 'paint/api/v0.2/project/';
  if(mode == 'asset')
    return get_endpoint_root() + 'paint/api/v0.2/asset/';
  if(mode == 'asset-uploads')
    return get_endpoint_root() + 'paint/api/v0.2/asset/uploads/';
  if(mode == 'asset-generated')
    return get_endpoint_root() + 'paint/api/v0.2/asset/generated/';
  if(mode == 'queue') {
    if(q_id !== null)
      return get_endpoint_root() + 'paint/api/v0.2/queue/' + q_id + '/';
    return get_endpoint_root() + 'paint/api/v0.2/queue/';
  }
  if(mode == 'merge')
    return get_endpoint_root() + 'paint/api/v0.2/merge/';
  if(mode == 'save')
    return get_endpoint_root() + 'paint/api/v0.2/save_project/';
  if(mode == 'flatten')
    return get_endpoint_root() + 'paint/api/v0.2/flatten/';

  // growth shit
  if(mode == 'waitlist')
    return get_endpoint_root() + 'accounts/waitlist_signup/';
}

export function get_media_url(fnom) {
  // for local testing
  // return 'http://127.0.0.1:81/static/media/' + fnom;
  return get_endpoint_root() + 'static/media/' + fnom;
}

export function get_image_url(image_uuid, image_timestamp = null) {
  var ret = get_endpoint_root() + 'paint/images/' + image_uuid + '.png';
  if(image_timestamp !== null)
    ret += '?' + image_timestamp;
  return ret;
}

export function goto_url(url, new_tab = false) {
  if(new_tab)
    window.open(url, '_blank');
  else
    window.location.href = url;
}

export function send_registration_request(email, on_start = () => {}, on_success = () => {}, on_error = () => {}) {
    on_start();
    console.log(email);

    axios.post(
        get_api_endpoint('waitlist'),
        {
          'email': email,
        }
      )
      .then((response) => {
        on_success();
      })
      .catch((err) => {
        // fail silently
        console.log(err);
        console.log(err.response);
        on_error();
      });
  }