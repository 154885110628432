import React, { createRef } from 'react';
import { Row, Col, Container, Form, Button, Dropdown, Navbar, Modal, Nav } from 'react-bootstrap';
import axios from "axios";

import './LandingPage.css';
import * as lib from './lib';

export class MenuBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  am_logged_in() {
    return this.props.user.user_id !== null;
  }

  // when logged in, link to paint instead
  render_login_link() {
    if(!this.am_logged_in())
      return (
        <Nav.Link href="/accounts/login/">Log in</Nav.Link>
      );
    return (
      <Dropdown>
        <Dropdown.Toggle id="profile-menu" as="div" className="pointer">
          Profile
        </Dropdown.Toggle>
        <Dropdown.Menu vertical className='border border-dark'>
          <Dropdown.Header>{this.props.user.user_name}</Dropdown.Header>
          <Dropdown.Item
            onClick = {() => {lib.goto_url('/v2v/t2v/');}}
          >
            T2v
          </Dropdown.Item>
          <Dropdown.Item
            onClick = {() => {lib.goto_url('/v2v/');}}
          >
            V2v
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick = {() => {lib.goto_url('/accounts/logout/');}}
          >
            Log Out
          </Dropdown.Item>
        </Dropdown.Menu >
      </Dropdown>
    );
  }

  render_signup_button() {
    if(!this.am_logged_in())
      return (
        <Col xs={1}>
          <Button
            onClick = { () => {
              this.props.signup_ref.current.scrollIntoView();
            }}
          >
            Sign up
          </Button>
        </Col>
      );
  }

  render() {
    return (
      <Navbar bg="light" expand="md" className='w-100'>
        <Col className="synaptic-nav-home">
          <Navbar.Brand href="/">{this.props.app}</Navbar.Brand>
        </Col>
        <Col xs={1}>
          <Dropdown>
            <Dropdown.Toggle id="help-menu" as="div" className="pointer">
              Help
            </Dropdown.Toggle>
            <Dropdown.Menu vertical className='border border-dark'>
              <Dropdown.Item
                tag = "a"
                href = "https://www.youtube.com/watch?v=I2-w73tPh_U&list=PL6On41at3H_BFY4ZktVfVdUGduRl7FL-P&index=2"
              >
                Tutorials
              </Dropdown.Item>
              <Dropdown.Item
                tag = "a"
                href = "https://www.youtube.com/watch?v=ceSbHTWg6pE&list=PL6On41at3H_CEl5-2MKUnocAL1OThiPhH"
              >
                Demos
              </Dropdown.Item>
              <Dropdown.Item
                disabled = {!this.am_logged_in()}
                tag = "a"
                href = {this.am_logged_in() ?
                  "https://discord.gg/ZDru9q3T2x" : null
                }
              >
                Discord
              </Dropdown.Item>
              <Dropdown.Item
                tag = "a"
                href = "https://www.reddit.com/r/synaptic/"
              >
                Reddit
              </Dropdown.Item>
              <Dropdown.Item
                tag = "a"
                href = "https://twitter.com/synapticpaint"
              >
                Twitter
              </Dropdown.Item>
              <Dropdown.Item
                tag = "a"
                href = "mailto:synapticpaint+support@gmail.com"
              >
                Contact Support
              </Dropdown.Item>
            </Dropdown.Menu >
          </Dropdown>
        </Col>
        <Col xs={1}>
          {this.render_login_link()}
        </Col>
          {this.render_signup_button()}
      </Navbar>
    );
  }
}

export class WaitlistBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className = "mb-5 mt-5 bg-light" ref = {this.props.signup_ref}>
        <Row className = "p-5">
          <Col>
            <div className = "fw-bold fs-1-5">
              Sign up for the waitlist today
            </div>
          </Col>
        </Row>
        <Row className = "px-5" style = {{paddingBottom: "3rem"}}>
          <Col xs = {12} sm ={8} md ={6} lg = {6}>
            <Form.Control
              type = "text"
              onChange = {(e) => {this.props.update_email(e.target.value);}}
              placeholder = "Your Email"
              value = {this.props.email}
              className = "fs-1-5"
            />
          </Col>
          <Col xs = {12} sm ={4} md ={6} lg = {6}>
            <Button
              style = {{width: "100%"}}
              className = "fs-1-5"
              disabled = {this.props.status == 'registering' || this.props.status == 'success'}
              onClick = {() => {
                lib.send_registration_request(
                  this.props.email,
                  this.props.on_start,
                  this.props.on_success,
                  this.props.on_error
                );
              }}
            >
              Sign up
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export class WaitlistResponseModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    if(this.props.status == "success")
      return (
        <Modal
          show = {this.props.show_response_modal}
          onHide = {this.props.hide_response_modal}
          size = "md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  You are now on the waitlist! Your email is {this.props.email}.
                </p>
                <p>
                  If you have any questions or concerns, please reach out to <a href="mailto:synapticpaint+support@gmail.com">support</a>.
                </p>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      );
    if(this.props.status == "error")
      return (
        <Modal
          show = {this.props.show_response_modal}
          onHide = {this.props.hide_response_modal}
          size = "md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  There was a problem with your registration. Please wait momentarily and try again.
                </p>
                <p>
                  If the problem persists, please reach out to <a href="mailto:synapticpaint+support@gmail.com">support</a>.
                </p>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      );
  }
}
