import React, { createRef } from 'react';
import { Row, Col, Container, Form, Button, Dropdown, Navbar, Modal, Nav, Card, } from 'react-bootstrap';
import axios from "axios";

import './LandingPage.css';
import * as lib from './lib';
import { MenuBar, WaitlistBar, WaitlistResponseModal, } from './components'

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screen_width: 1,

      // for waitlist
      email: "",
      show_response_modal: false,

      user_id: null,
      user_name: null,
    };

    this.signup_ref = React.createRef();
    this.youtube_frame_ref = React.createRef();
    this.youtube_frame_ref2 = React.createRef();

    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  }

  componentDidMount() {
    this.setState({
      screen_width: window.innerWidth
    });
    window.addEventListener('resize', () => {
        this.setState({
            screen_width: window.innerWidth
          },
          () => {
            console.log(this.state.screen_width);
          }
        );
    }, false);
    this.fetch_user();
  }

  fetch_user() {
    axios.get(
        lib.get_api_endpoint('user'),
      )
      .then((response) => {
        this.setState({
          user_id: response.data.id,
          user_name: response.data.username,
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  }

  render_navbar() {
    return (
      <MenuBar
        user = {{
          user_id: this.state.user_id,
          user_name: this.state.user_name,
        }}
        signup_ref = {this.signup_ref}
        app = "Synaptic"
      />
    );
  }

  get_yt_demo_width(c_ref) {
    if(c_ref.current !== null) {
      return c_ref.current.getBoundingClientRect().width;
    }
  }

  get_yt_demo_height(c_ref) {
    var max_width = 889;
    var max_height = 500;

    return Math.round(this.get_yt_demo_width(c_ref) / max_width * max_height);
  }

  render() {

    return (
      <Container fluid>
        {this.render_navbar()}
        <Container>
          <Row className = "mb-2">
            <div className = "fw-bold display-5 mb-4">
              AI Powered Content Creation
            </div>
          </Row>

          <Row className = "mb-4">
            <Col>
              Synaptic uses AI to make content creation easier and more accessible for everyone.
              Create illustrations, marketing assets, video content, and more, even if you have no art or design background.
            </Col>
          </Row>

          <Row>
            <Col>
              <Row ref = {this.youtube_frame_ref}>
                <iframe
                  width = {this.get_yt_demo_width(this.youtube_frame_ref)}
                  height = {this.get_yt_demo_height(this.youtube_frame_ref)}
                  src = "https://www.youtube.com/embed/X0AhqMhEe-c?loop=1&playlist=X0AhqMhEe-c"
                  title="Synaptic T2v Demo"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="allowfullscreen"
                />
              </Row>

              <Row className = "fw-bold fs-1-5 mt-3 mb-2">
                <Col>
                  Text To Video (T2v)
                </Col>
              </Row>

              <Row className = "mb-2">
                <Col>
                  <ul>
                    <li>
                      Create your next movie or marketing video using nothing but words
                    </li>
                  </ul>
                </Col>
                <Col>
                </Col>
              </Row>

            </Col>
            <Col>

              <Row ref = {this.youtube_frame_ref2}>
                <iframe
                  width = {this.get_yt_demo_width(this.youtube_frame_ref2)}
                  height = {this.get_yt_demo_height(this.youtube_frame_ref2)}
                  src = "https://www.youtube.com/embed/wouqQJ9TA18?loop=1&playlist=wouqQJ9TA18"
                  title="Synaptic V2v Demo"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="allowfullscreen"
                />
              </Row>

              <Row className = "fw-bold fs-1-5 mt-3 mb-2">
                <Col>
                  Video To Video (V2v)
                </Col>
              </Row>

              <Row className = "mb-2">
                <Col>
                  <ul>
                    <li>
                      Film using a smart phone
                    </li>
                    <li>
                      Film anywhere
                    </li>
                    <li>
                      Turn your actors into any character regardless of look
                    </li>
                    <li>
                      Apply makeup in post
                    </li>
                    <li>
                      Relight the scene
                    </li>
                    <li>
                      Change the environment
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <li>
                      No expensive camera equipment
                    </li>
                    <li>
                      No green screen required
                    </li>
                    <li>
                      No specialized motion capture equipment
                    </li>
                  </ul>
                </Col>
              </Row>

            </Col>
          </Row>

        </Container>
        <Container>
          <WaitlistBar
            on_start = { () => {
              this.setState({
                status: 'registering',
              });
            }}
            on_success = {() => {
              this.setState({
                status: 'success',
                show_response_modal: true,
              });
            }}
            on_error = {() => {
              this.setState({
                status: 'error',
                show_response_modal: true,
              });
            }}
            signup_ref = {this.signup_ref}
            email = {this.state.email}
            update_email = {(e) => {this.setState({email: e});}}
          />
        </Container>
        <WaitlistResponseModal
          show_response_modal = {this.state.show_response_modal}
          hide_response_modal = {() => {this.setState({show_response_modal: false});}}
          email = {this.state.email}
          status = {this.state.status}
        />
      </Container>
    );
  }
}

export default LandingPage;